import React, { useEffect, useState } from 'react';

const Troubleshoot = () => {
  const [iframeSrc, setIframeSrc] = useState('');

  const tenantname = JSON.parse(window.localStorage.getItem("tenant_name"));
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;
  const kibanaToken = user_details.data.kibana_token;

  const encodedString = window.localStorage.getItem("encoded_event_list");
  // const encoded_event_list = window.localStorage.getItem("event_list").split(",");
  const eventList = window.localStorage.getItem("event_list");
  const encoded_event_list = eventList ? eventList.split(",") : [];

  
  useEffect(() => {
    const getQueryParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const collectorType = urlParams.get('collector_type');
      const eventTime = urlParams.get('event_time');
      return { collectorType, eventTime };
    };

    const getTimeRange = (eventTime) => {
      const eventDate = new Date(eventTime);
      const fromDate = new Date(eventDate);
      const toDate = new Date(eventDate);

      fromDate.setHours(fromDate.getHours() - 1);
      toDate.setHours(toDate.getHours() + 1);

      return {
        from: fromDate.toISOString(),
        to: toDate.toISOString(),
      };
    };

    const { collectorType, eventTime } = getQueryParams();
    if (collectorType && eventTime) {
      const { from, to } = getTimeRange(eventTime);

      let iframeUrl = '';
      
      switch (collectorType) {
        case 'CrowdStrikeFalconHost': {
          iframeUrl = encodedString === null
            ?
            `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/cf153a90-561d-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.collectorEventName,data.productEventCategory,data.dstHostname,data.dstUsername),filters:!(('$state':(store:appState),meta:(alias:CrowdStrikeFalconHost,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:CrowdStrikeFalconHost),type:phrase),query:(match_phrase:(data.collectorType:CrowdStrikeFalconHost)))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`
            :
            `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/cf153a90-561d-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.collectorEventName,data.productEventCategory,data.dstHostname,data.dstUsername),filters:!(('$state':(store:appState),meta:(alias:CrowdStrikeFalconHost,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:CrowdStrikeFalconHost),type:phrase),query:(match_phrase:(data.collectorType:CrowdStrikeFalconHost))),('$state':(store:appState),meta:(alias:ml_filter_events,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:terms,negate:!f,type:custom,value:'${encodedString}'),terms:(data.collectorEventName:!('${encoded_event_list.join("','")}')))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`
            break;
        }
        case 'CiscoMerakiFirewall': {
          iframeUrl = encodedString === null
          ?
          `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/6ff0d830-561d-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.dstIPAddress,data.collectorEventName,data.productAction,data.productHostname,data.requestURL),filters:!(('$state':(store:appState),meta:(alias:CiscoMerakiFirewall,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:CiscoMerakiFirewall),type:phrase),query:(match_phrase:(data.collectorType:CiscoMerakiFirewall)))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!(!(data.eventTime,desc)))&embed=true&show-time-filter=true`
          :
          `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/6ff0d830-561d-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.dstIPAddress,data.collectorEventName,data.productAction,data.productHostname,data.requestURL),filters:!(('$state':(store:appState),meta:(alias:CiscoMerakiFirewall,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:CiscoMerakiFirewall),type:phrase),query:(match_phrase:(data.collectorType:CiscoMerakiFirewall))),('$state':(store:appState),meta:(alias:ml_filter_events,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:terms,negate:!f,type:custom,value:'${encodedString}'),terms:(data.collectorEventName:!('${encoded_event_list.join("','")}')))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!(!(data.eventTime,desc)))&embed=true&show-time-filter=true`
          break;
        }
        case 'CiscoASA': {
          iframeUrl = encodedString === null
          ?
          `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/309de830-561d-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.collectorSeverity,data.collectorEventName,data.productAction,data.srcIPAddress,data.srcUsername),filters:!(('$state':(store:appState),meta:(alias:CiscoASA,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:CiscoASA),type:phrase),query:(match_phrase:(data.collectorType:CiscoASA)))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`
          :
          `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/309de830-561d-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.collectorSeverity,data.collectorEventName,data.productAction,data.srcIPAddress,data.srcUsername),filters:!(('$state':(store:appState),meta:(alias:CiscoASA,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:CiscoASA),type:phrase),query:(match_phrase:(data.collectorType:CiscoASA))),('$state':(store:appState),meta:(alias:ml_filter_events,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:terms,negate:!f,type:custom,value:'${encodedString}'),terms:(data.collectorEventName:!('${encoded_event_list.join("','")}')))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`
          break;
        }
        case 'AmazonAWSELB': {
          iframeUrl = encodedString === null
          ?
          `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/a2eac570-561d-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.srcIPAddress,data.dstIPAddress,data.collectorEventName,data.eventOutcome,data.requestMethod,data.requestURL),filters:!(('$state':(store:appState),meta:(alias:AmazonAWSELB,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:AmazonAWSELB),type:phrase),query:(match_phrase:(data.collectorType:AmazonAWSELB)))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`
          :
          `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/a2eac570-561d-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.srcIPAddress,data.dstIPAddress,data.collectorEventName,data.eventOutcome,data.requestMethod,data.requestURL),filters:!(('$state':(store:appState),meta:(alias:AmazonAWSELB,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:AmazonAWSELB),type:phrase),query:(match_phrase:(data.collectorType:AmazonAWSELB))),('$state':(store:appState),meta:(alias:ml_filter_events,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:terms,negate:!f,type:custom,value:'${encodedString}'),terms:(data.collectorEventName:!('${encoded_event_list.join("','")}')))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`
          break;
        }
        case 'AmazonAWSConsole': {
            iframeUrl = encodedString === null
            ?
            `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/6d54c600-561c-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.srcUsername,data.collectorEventName,data.productEventCategory,data.srcIPAddress,data.aisaacStr4),filters:!(('$state':(store:appState),meta:(alias:AmazonAWSConsole,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:AmazonAWSConsole),type:phrase),query:(match_phrase:(data.collectorType:AmazonAWSConsole))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorEventName,negate:!t,params:(query:AssumeRoleWithWebIdentity),type:phrase),query:(match_phrase:(data.collectorEventName:AssumeRoleWithWebIdentity))),('$state':(store:appState),meta:(alias:!n,disabled:!t,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.aisaacStr3,negate:!f,params:(query:IAMUser),type:phrase),query:(match_phrase:(data.aisaacStr3:IAMUser)))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`
            : 
            `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/6d54c600-561c-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.srcUsername,data.collectorEventName,data.productEventCategory,data.srcIPAddress,data.aisaacStr4),filters:!(('$state':(store:appState),meta:(alias:AmazonAWSConsole,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:AmazonAWSConsole),type:phrase),query:(match_phrase:(data.collectorType:AmazonAWSConsole))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorEventName,negate:!t,params:(query:AssumeRoleWithWebIdentity),type:phrase),query:(match_phrase:(data.collectorEventName:AssumeRoleWithWebIdentity))),('$state':(store:appState),meta:(alias:!n,disabled:!t,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.aisaacStr3,negate:!f,params:(query:IAMUser),type:phrase),query:(match_phrase:(data.aisaacStr3:IAMUser))),('$state':(store:appState),meta:(alias:ml_filters,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:terms,negate:!f,type:custom,value:'${encodedString}'),terms:(data.collectorEventName:!('${encoded_event_list.join("','")}')))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`
            break;
        }            
        case 'UnixUnix':{
            iframeUrl = encodedString === null
            ?
            `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/9cfb6cf0-5645-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.srcUsername,data.collectorSeverity,data.collectorEventName,data.aisaacStr1,data.rawEvent),filters:!(('$state':(store:appState),meta:(alias:UnixUnix,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:UnixUnix),type:phrase),query:(match_phrase:(data.collectorType:UnixUnix)))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!(!(data.eventTime,desc)))&embed=true&show-time-filter=true`
            :
            `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/9cfb6cf0-5645-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(data.srcUsername,data.collectorSeverity,data.collectorEventName,data.aisaacStr1,data.rawEvent),filters:!(('$state':(store:appState),meta:(alias:UnixUnix,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:data.collectorType,negate:!f,params:(query:UnixUnix),type:phrase),query:(match_phrase:(data.collectorType:UnixUnix))),('$state':(store:appState),meta:(alias:ml_filter_events,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:terms,negate:!f,type:custom,value:'${encodedString}'),terms:(data.collectorEventName:!('${encoded_event_list.join("','")}')))),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!(!(data.eventTime,desc)))&embed=true&show-time-filter=true`
            break;
        }
        default:
          iframeUrl = `https://devsaas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(columns:!(_source),filters:!(),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`;
          break;
      }

      setIframeSrc(iframeUrl);
    }
  }, []);

  return (
    <>
    <div style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <iframe
        src={iframeSrc}
        title="Troubleshoot"
        style={{ border: 'none', width: '100%', height: '100%', marginTop: '7%' }}
      />
    </div>
    </>
  );
};

export default Troubleshoot;