import React, { useState } from 'react';
import axios from 'axios'; // Import axios
import { BaseUrl } from '../constants/constants';

const Experiment = () => {
    const [tenantname, setTenantName] = useState(JSON.parse(window.localStorage.getItem("tenant_name")));
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const token = user_details.data.accessToken;
    const kibanaToken = user_details.data.kibana_token;

    const [loading, setLoading] = useState(false);
    const [iframeSrc, setIframeSrc] = useState(`https://devsaas.khika.in/kibana/app/dashboards?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/25e64e80-49ea-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:Godigit-DB1,viewMode:view)&embed=true&show-time-filter=true`);
    const [previousIframeSrc, setPreviousIframeSrc] = useState(null);
    const [showBackButton, setShowBackButton] = useState(false);

    const handleReportSelection = (chatQuery) => {
        setLoading(true);
        setPreviousIframeSrc(iframeSrc);

        const data = JSON.stringify({
            "fromTS": "2024-06-30T11:23:53.126Z",
            "toTS": "2024-08-01T11:23:53.126Z",
            "chat_query": chatQuery
        });

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BaseUrl + '/user/daml_query',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}`
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                const event_list = response.data.event_list;
                const encoded_event_list = event_list.map(event => encodeURIComponent(event));
                window.localStorage.setItem("event_list", encoded_event_list);
                const encodedString = encodeURIComponent(JSON.stringify({ "data.collectorEventName": event_list }));
                window.localStorage.setItem("encoded_event_list", encodedString);
                
                const dashboardId = '25e64e80-49ea-11ef-94a5-9d045b0c62c4';
                const baseURL = `https://devsaas.khika.in/kibana/app/dashboards?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/${dashboardId}`;
                const newIframeSrc = `${baseURL}?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(description:'',filters:!(('$state':(store:appState),meta:(alias:ml_filter_events,disabled:!f,index:'553b6600-5095-11ef-94a5-9d045b0c62c4',key:terms,negate:!f,type:custom,value:'${encodedString}'),terms:(data.collectorEventName:!('${encoded_event_list.join("','")}')))),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:Godigit-DB1,viewMode:view)&embed=true&show-time-filter=true`;

                setIframeSrc(newIframeSrc);
                setShowBackButton(true);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error making the API request:', error);
                setLoading(false);
            });
    };

    const handleBackClick = () => {
        setIframeSrc(previousIframeSrc);
        setShowBackButton(false);
    };

    return (
        <div style={{ height: '100vh', width: '100%', position: 'relative' }}>
            {!showBackButton ? (
                <select
                    onChange={(e) => handleReportSelection(e.target.value)}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        zIndex: 10,
                        padding: '10px 20px',
                        fontSize: '14px'
                    }}
                >
                    <option value="">AI Use Case/Report</option>
                    <option value="Avoid network events.what are crucial infra modifications which can have major impact which can break cloud infrastructure. Ignore INFO level updates">Infra Change Report</option>
                    <option value="Identify any performance bottlenecks or system errors that are occurring with increased frequency. Focus on events indicating performance degradation or recurring errors. Exclude standard informational events and routine maintenance logs. Provide the response in list of events in json only">Performance bottlenecks/Increased Error Rate</option>
                    <option value="Identify any unusual authentication patterns or potential account compromise attempts. Focus on events that deviate from normal login behaviors, such as multiple failed login attempts, login attempts from unusual locations, or abnormal login times. Exclude routine login activities and standard user authentication logs. Provide the response in JSON format, highlighting events with notable deviations or potential security concerns. Reply with just events list">Authentication failure patterns</option>
                    <option value="anything deleted in infra? Provide the results in JSON format list of such events">Configuration deletion</option>
                </select>
            ) : (
                <button
                    onClick={handleBackClick}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        zIndex: 10,
                        padding: '10px 20px',
                        fontSize: '14px'
                    }}
                >
                    Back
                </button>
            )}
            {loading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 11
                }}>
                    <div className="loader"></div>
                </div>
            )}
            <iframe
                src={iframeSrc}
                style={{
                    border: 'none',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1
                }}
                title="Kibana Dashboard"
            />
        </div>
    );
};

export default Experiment;