import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Typography, Link, Grid } from '@mui/material';
import axios from 'axios';
import wave1 from '../images/wave_latest.svg';
import signupImage from '../images/login (1).svg';
import logo from '../images/khikalogo.png';
import { BaseUrl } from '../constants/constants';
import useFullPageLoader from './hooks/useFullPageLoader';

const NewSignUp = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [checked, setChecked] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSignUp = () => {
    showLoader();
    const data = JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email_address: email,
      password: password,
      confirm_password: confirmPassword
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/auth/signup',
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        hideLoader();
        if (response.status === 200) {
          window.location.href = '/login';
        }
      })
      .catch((error) => {
        hideLoader();
        alert("Error: " + error.response.data.message);
      });
  };

  return (
    <div>
      <img className="wave" src={wave1} alt="Wave Background" style={{ position: 'absolute', width: '100%', top: '0' }} />
      <div className="container-login" style={{ marginTop: '40px' }}>
        <div className="img">
          <img className="login-image" src={signupImage} alt="Sign Up Illustration" />
        </div>

        <div className="login-content">
          <form className="form-class-login" onSubmit={(e) => { e.preventDefault(); handleSignUp(); }}>
            <div className="row">
              <div className="col" style={{ textAlign: 'center' }}>
                <img className="actionate-img" src={logo} width="50%" alt="Logo" />
              </div>
            </div>

            <div className="row">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="row">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="row">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="row">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    By signing up, you agree to the{' '}
                    <Link href="/Khika-Privacy-Policy.pdf" underline="always" color="primary" target='_blank'>
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link href="/Khika-License-Terms-Conditions 1.pdf" underline="always" color="primary" target='_blank'>
                      License Terms & Conditions
                    </Link>.
                  </Typography>
                }
              />
            </div>
            <div className="row">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '20px' }}
                disabled={!checked}
                type="submit"
              >
                Sign Up
              </Button>
            </div>
            <div className="row" style={{ marginTop: '10px', textAlign: 'center' }}>
              <Link href="/login" variant="body2">
                Already have an account? Login
              </Link>
            </div>
          </form>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default NewSignUp;
